<template>
  <div>
    <b-row class="mt-5">
      <b-col md="4" v-for="(banque,index) in banques"  :key="index">
        <b-list-group>
          <b-list-group-item><a :href="'/#/banque/details/'+banque.id">{{banque.id}}</a></b-list-group-item>
          <b-list-group-item>{{banque.titre}}</b-list-group-item>
          <b-list-group-item>{{banque.sum}}</b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data(){
    return {
      banques:[]
    };
  },
  components: {
  },
  watch: {
  },
  methods: {
  },
  computed: {
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
    this.$store.back.ajax('/banque/liste', null, (res) => {
      if(res.status === true){
        this.banques = res.data
      }
    });
  }
};
</script>
